import './App.css';
import StarsCanvas from './StarBackground';

function App() {
  return (
      <StarsCanvas />
  );
}

export default App;
